<template>
    <div>
        <Pop ref="pop" position="Bottom">
            <template v-slot:popContent>
                <div class="selectContent">
                    <div class="timeHead">
                        <span class="timePickerLeft">
                            <slot name="leftBtn"></slot>
                        </span>
                        {{title}}
                        <span class="timePickerOk" @click="overChoose">完成</span>
                    </div>
                    <div class="pickerSlotContent">
                        <div class="pickerContent" :class="{'pickerSingle':single,'bor1':!single}">
                            <span v-if="!single" class="timePickerTxt">开始</span>
                            <div class="timeSlotBar" :class="{'timesLeft':!single,'timeSlotBar2':single}">
                                <ScrollPicker val="val" :pickerList="init('startM','hour')"
                                              @change-picker="onchangePicker"></ScrollPicker>
                                <!--<mt-picker :slots="init('startM')" @change="onchangePicker"></mt-picker>-->
                            </div>
                            <span class="timeDot" :class="{'timeDot2':single}">:</span>
                            <div class="timeSlotBar" :class="{'timeSlotBar2':single}">
                                <ScrollPicker val="val" :pickerList="init('startS','min')"
                                              @change-picker="onchangePicker"></ScrollPicker>
                                <!--<mt-picker :slots="init('startS')" @change="onchangePicker"></mt-picker>-->
                            </div>
                        </div>
                        <div v-if="!single" class="pickerContent">
                            <span class="timePickerTxt">结束</span>
                            <div class="timeSlotBar timesLeft">
                                <ScrollPicker val="val" :pickerList="init('endM','hour')"
                                              @change-picker="onchangePicker"></ScrollPicker>
                                <!--<mt-picker :slots="init('endM')" @change="onchangePicker"></mt-picker>-->
                            </div>
                            <span class="timeDot">:</span>
                            <div class="timeSlotBar">
                                <ScrollPicker val='val' :pickerList="init('endS','min')"
                                              @change-picker="onchangePicker"></ScrollPicker>
                                <!--<mt-picker :slots="init('endS')" @change="onchangePicker"></mt-picker>-->
                                <!--<STimePiker></STimePiker>-->
                            </div>
                        </div>
                        <div style="clear: both"></div>
                    </div>
                </div>
            </template>
        </Pop>
    </div>
</template>

<script>
    //    import STimePiker from './STimePicker/sTimePiker'
    export default {
        name: "timePicker",
        props: [
            'limit',//是否限制大于当前时间
            'connect',//连接符
            'title',//标题
            'single',//单选/区间
            'hourLimit',//小时间隔
            'timeLimit',//分钟间隔
        ],
        components: {
//            STimePiker
        },
        data() {
            return {
                timeGroup: {
                    startM: '',
                    startS: '',
                    endM: '',
                    endS: ''
                },
                times: {},
            }
        },
        methods: {
            init(name, type) {
                let end = 60;
                if (type == 'hour') {
                    end = 24;
                }
                let arr = [];
                for (let i = 0; i < end; i++) {
                    if (type == 'hour' && this.hourLimit) {
                        if (!(i % this.hourLimit)) {
                            arr.push({
                                val: this.parseNum(i),
                                keys: this.parseNum(i),
                                name: name
                            });
                        }
                    } else if (type == 'min' && this.timeLimit) {
                        if (!(i % this.timeLimit)) {
                            arr.push({
                                val: this.parseNum(i),
                                keys: this.parseNum(i),
                                name: name
                            });
                        }
                    } else {
                        arr.push({
                            val: this.parseNum(i),
                            keys: this.parseNum(i),
                            name: name
                        });
                    }
                }
                if (arr.length < 18) {
                    // arr = this.supplementTime(arr)
                    let len = Math.ceil(18 / arr.length);
                    let newArr = arr;
                    for (let j = 0; j < len; j++) {
                        newArr = newArr.concat(arr);
                    }
                    arr = newArr;
                }
                return arr;
            },
            parseNum(num) {
                if (num < 10) {
                    num = '0' + num;
                }
                return num;
            },
            onchangePicker(e) {
                this.timeGroup[e.name] = e.val;
            },
            openPop() {
                this.$refs.pop.changePop();
            },
            overChoose() {
                this.timeGroup.startM = this.timeGroup.startM || '00';
                this.timeGroup.startS = this.timeGroup.startS || '00';
                this.timeGroup.endM = this.timeGroup.endM || '00';
                this.timeGroup.endS = this.timeGroup.endS || '00';
                let start = new Date(("1970/1/1 " + this.timeGroup.startM + ':' + this.timeGroup.startS));
                let end = new Date(("1970/1/1 " + this.timeGroup.endM + ':' + this.timeGroup.endS));
                if (start > end && !this.single) {
                    this.$store.commit('setValueAlert', '开始时间不能大于结束时间！');
                    return;
                }
                let newDate = new Date();
                let hourLimit = new Date(("1970/1/1 " + newDate.getHours() + ':' + newDate.getMinutes()));
                if (this.limit && hourLimit > start) {
                    this.$store.commit('setValueAlert', '请选择大于当前时间的时间！');
                    return
                }
                this.times = this.timeGroup;
                this.times.start = this.timeGroup.startM + ':' + this.timeGroup.startS;
                this.times.end = this.timeGroup.endM + ':' + this.timeGroup.endS;
                this.times.range = this.times.start + '-' + this.times.end;
                this.$emit('setTime', this.times);
                this.openPop();
            }
        }
    }
</script>

<style lang="scss" scoped>
    

    .timeHead {
        text-align: center;
        font-weight: 500;
        font-size: .15rem;
        padding: .2rem;
        position: relative;
    }

    .timePickerOk {
        position: absolute;
        top: .12rem;
        font-size: .12rem;
        padding: .12rem .12rem 0 .12rem;
    }

    .timePickerOk {
        color:  var(--themeColor);
        right: .3rem;
    }

    .timePickerLeft {
        left: .3rem;
    }

    .timeSlotBar {
        width: 35%;
        overflow: hidden;
        float: left;
    }

    .timeSlotBar2 {
        width: 50%;
    }

    .pickerContent {
        float: left;
        width: 44%;
        padding-right: 5%;
        position: relative;
    }

    .pickerSingle {
        width: 89%;
        padding-left: 5%;
    }

    .timesLeft {
        margin-left: 30%;
    }

    .bor1 {
        border-right: 1px solid #F5F5F5;
    }

    .timePickerTxt {
        font-size: .12rem;
        font-weight: 500;
        position: absolute;
        border-top: 1px solid #F5F5F5;
        border-bottom: 1px solid #F5F5F5;
        height: .4rem;
        line-height: .4rem;
        left: .1rem;
        top: .7rem;
        width: 30%
    }

    .timeDot {
        position: absolute;
        top: .9rem;
        left: 58%;
        font-weight: 600;
    }

    .timeDot2 {
        left: 50%;
    }
</style>